import React from 'react'
import { Link } from 'react-router-dom';
import SustainFeatureBox from './SustainFeatureBox';
import SustainBottomFeatureBox from './SustainBottomFeatureBox';

function Sustainability({images,bottomimages,greenBottomSustain,greenSustain,sustainAbility,showTopLink=true,showSustainPara=true,sustainPara,greenSustainImage,greenButtomSustainImage,greenButtomSustainImageAltTag='',
greenSustainImageAltTag='',sustainibilitySlug}) {
    // const sustainPara = "We are deeply conscious of the Maldives' natural ecosystem and our responsibility to protect it. In pursuit of this commitment, we operate sustainably, prioritizing the well-being of our guests and the preservation of the island's natural surroundings."
    const larnMore = 'LEARN MORE'
  return (
    <section>
        <div className='sustainability section-padding pt-0'>
            <div className='container'>
             <div className='row'>
                    <div className='col-lg-9'>
                        <div className='top-left-side-sustainability'>
                            <h2 className='georgia letter-spacing-2 text-uppercase'>{sustainAbility}</h2>
                            {showSustainPara?(<p className='mb-0'>{sustainPara}</p> ):("")}                            
                        </div>
                    </div>
                    {showTopLink? (<div className='col-lg-3'>
                        <div className='learnmore text-end'>
                            <Link to={sustainibilitySlug} className='btn primary theme-btn fw-medium text-white text-uppercase border-theme'>{larnMore}</Link>
                        </div>
                    </div>) :("")}
                    
                </div>
                
                <div className='row mt-lg-5 mt-4'>
                    <SustainFeatureBox images={images} greenSustain={greenSustain} greenSustainImage={greenSustainImage} greenSustainImageAltTag={greenSustainImageAltTag}/>
                </div>

                <div className='row mt-4'>
                    <SustainBottomFeatureBox greenBottomSustain={greenBottomSustain} bottomimages={bottomimages}  greenButtomSustainImage={greenButtomSustainImage} greenButtomSustainImageAltTag={greenButtomSustainImageAltTag}/>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Sustainability