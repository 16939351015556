import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import * as querydata from "../services/dataServices";
import { useQuery } from 'react-query';
import Image from './Image';

function OfferSlider({data,images,classview='',offersSlug}) {

  const gallerySettings = {
    arrow: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.data?.data?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
};
    const settings = {...gallerySettings}
    const targetLink = "_blank";
    function truncateAtLastWord(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      const truncatedText = text.slice(0, maxLength);
      return truncatedText;
    }

    const getWordCountValue = useQuery(
      ["AspectCount", "special_offer_wordcount_in_card"], // Using an array as the query key with offerId
      () => querydata.getAspectRatio("special_offer_wordcount_in_card")
    );

    const wordCount =
      getWordCountValue &&
      getWordCountValue.data &&
      getWordCountValue.data.data.setting_value;
  

  return (
    <Slider {...settings}>
        
            {/* {images.map( (image,index) =>(
              <div key={index} className='gallery-card'>
                <div className={`card-image overflow-hidden ${classview}`}>
                  <img className='w-100' src={image.imageUrl} alt='' title='' />
                </div>
                <div className='gallery-text-box rounded-4 ms-auto me-auto text-center'>
                    <small className='text-uppercase'>{image.offerSmallText}</small>
                  <h4 className='georgia mt-3 mb-3'>{image.galleryHeading}</h4>
                  <span>{image.offerSmallHeading}</span>
                  <p>{parse(image.galleryPara)} <Link className='text-capitalize' to={image.learnMore}>{image.learnMoreLink} <FontAwesomeIcon className='ms-1' icon={faArrowRight} /></Link></p>
                  <div className='book-offer mt-4'>
                    <Link to={image.link} target={image.targetLink} className='btn primary theme-btn fw-medium text-white text-uppercase border-theme'>Book Now</Link>
                  </div>
                </div>
            </div>
            ))} */}
              {data?.data?.data?.map((image) => {
        const limitedDescription =
          image.description.length > 100
          ?(
            <>
              {`${image.description.substring(0, 100).replace(/(?:\W*\w*)\s*$/, "")}...`}
            
            </>
          )
            : image.description;
            const parsedDescription = typeof limitedDescription === 'string' ? parse(limitedDescription) : limitedDescription;

        return (
          <div className="gallery-card" key={image.website_offer_id}>
            <div className={`card-image overflow-hidden ${classview}`}>
              {/* <img
                className="w-100"
                src={image.offer_image}
                alt={image.offer_image_alt_tag}
                title=""
              /> */}
              <Image
                className="w-100"
                src={image.offer_image}
                alt={image.offer_image_alt_tag}
                title=""
              />
            </div>
            <div className="gallery-text-box rounded-4 ms-auto me-auto text-center">
              <small className="text-uppercase">{`${image.resort_name} | Maldives `}</small>
              <h4 className="georgia mt-3 mb-3">
                {image.offer_name.length > wordCount ? (
                  <>
                    {parse(truncateAtLastWord(image.offer_name, wordCount))}
                    {" ...."}
                  </>
                ) : (
                  parse(image.offer_name)
                )}
              </h4>
              <span>
                {/* {`${parseInt(image.discount_percent)}% OFF`}{" "} */}
                {`${image.discount_text}`}{" "}
              </span>
              <p>
                {(parsedDescription)} &nbsp;
                <Link
                className="text-decoration-underline view-all"
                title="Learn More"
                to={`/${offersSlug}/${image.slug}`}
              >
                Learn More <FontAwesomeIcon icon={faArrowRight} className="ms-1 icon-font" />
              </Link>
              </p>

              <div className="book-offer mt-4">
                <Link
                  to={image.website_url}
                  target={targetLink}
                  className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })}

    </Slider>
  )
}

export default OfferSlider